import { render, staticRenderFns } from "./Podcasts.vue?vue&type=template&id=7a182130&scoped=true&"
import script from "./Podcasts.vue?vue&type=script&lang=js&"
export * from "./Podcasts.vue?vue&type=script&lang=js&"
import style0 from "./Podcasts.vue?vue&type=style&index=0&id=7a182130&prod&lang=scss&scoped=true&"
import style1 from "./Podcasts.vue?vue&type=style&index=1&id=7a182130&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a182130",
  null
  
)

export default component.exports